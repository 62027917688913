<template>
  <div :class="['header-bar_container', { collapse: isCollapse }]">
    <div class="logo">{{ isCollapse ? "调酒" : "调酒大师" }}</div>
    <div class="banner-ctan">
      <div class="collapse-btn fa fa-bars" @click="toggleMenuCollapse"></div>
      <div class="tabs-ctan">
        <cache-bar></cache-bar>
      </div>
      <el-popover
        ref="popover4"
        :show-arrow="false"
        :offset="0"
        placement="bottom"
        width="280"
        trigger="click"
        popper-class="header-bar_mine-popover"
      >
        <div class="mine-detail">
          <img
            class="avatar"
            src="@/assets/images/avatar.png"
            width="84"
            height="84"
          />
          <label>{{ $store.getters.userInfo.nickname }}</label>
          <span>{{
            $moment
              .unix($store.getters.userInfo.logintime)
              .format("yyyy-MM-DD HH:mm:ss")
          }}</span>
        </div>
        <div class="footer">
          <el-button type="danger" @click="logoutHandle">退出</el-button>
        </div>
        <template #reference>
          <div class="mine-ctan">
            <img
              class="avatar"
              src="@/assets/images/avatar.png"
              width="25"
              height="25"
            />
            <span>{{ $store.getters.userInfo.nickname }}</span>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
import cacheBar from "@/views/global/layout/cacheBar";
export default {
  components: { cacheBar },
  data() {
    return {};
  },
  computed: {
    isCollapse() {
      return this.$store.getters.menuCollapse;
    },
  },
  methods: {
    toggleMenuCollapse() {
      this.$store.dispatch("app/toggleMenuCollapse");
    },
    logoutHandle() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar_container {
  display: flex;
  flex-direction: row;
  .logo {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 50px;
    height: 50px;
    text-align: center;
    background: #222d32;
    // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    width: 230px;
    overflow: hidden;
    transition: all ease 0.5s;
  }

  .banner-ctan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;

    .collapse-btn {
      width: 45px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tabs-ctan {
      display: flex;
      flex: 1;
      width: 0;
    }

    .mine-ctan {
      min-width: 100px;
      max-width: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;
      color: #666;
      padding: 0 10px;

      .avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .mine-ctan,
    .collapse-btn {
      &:hover {
        background: rgba($color: #000000, $alpha: 0.02);
        cursor: pointer;
      }
    }
  }
}

.header-bar_container.collapse {
  .logo {
    width: 64px;
    transition: all ease 0.5s;
  }
}
</style>

<style lang="scss">
.header-bar_mine-popover {
  width: 280px;
  .mine-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #222d32;
    color: #ffffff;
    padding: 10px;
    line-height: 24px;
    font-size: 13px;

    img {
      border-radius: 50%;
      border: 3px solid rgba($color: #ffffff, $alpha: 0.2);
      margin-bottom: 10px;
    }
    label {
      font-size: 17px;
    }
  }
  .footer {
    background: #f9f9f9;
    padding: 10px;
    text-align: right;
  }
}
.header-bar_mine-popover.el-popover.el-popper {
  padding: 0;
}
</style>
