<template>
  <div class="cache-bar__container">
    <div class="tabs">
      <div
        :class="['tab-item', { active: activePath === item.path }]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="linkHandle(item)"
      >
        <i :class="item.meta.icon"></i>
        <span>{{ item.meta.title }}</span>
        <span
          class="item_close fa fa-remove"
          @click="removeHandle(item)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tabList() {
      return this.$store.getters.cacheList;
    },
    activePath() {
      return this.$route.fullPath;
    },
  },
  methods: {
    linkHandle(item) {
      this.$router.push(item);
    },
    removeHandle(item) {
      this.$store.dispatch("app/removeRouteCache", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.cache-bar__container {
  overflow-x: auto;

  .tabs {
    display: flex;
    flex-direction: row;
    height: 50px;

    .tab-item {
      font-size: 13px;
      color: #666;
      padding: 0 20px 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      white-space: nowrap;

      &:hover,
      &.active {
        color: #000;
        background: rgba($color: #000000, $alpha: 0.02);
        cursor: pointer;
      }

      i {
        margin-right: 6px;
      }

      .item_close {
        position: absolute;
        right: 0;
        font-size: 12px;
        font-weight: lighter;
        padding: 0 4px;
        display: none;
      }
      &:hover > .item_close {
        display: block;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
